import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';


import { Button, CircularProgress, List } from "@material-ui/core";
import CreateOrganizationDialog from "../CreateOrganizationDialog";
import CreateOrganizationAdminDialog from "../organizations/CreateOrganizationAdminDialog"
import OrgPricingDialog from "../organizations/OrgPricingDialog"
import UnitsLimitDialog from "./UnitsLimitDialog"
import { useStyles } from "../common/styles";

import {
    addOrgToState,
    deleteOrgFromState,
    updateOrgInState,
    updateOrgPricingTier
} from "../../modules/sdkOrganizations";

import {
    createOrganization,
    createOrganizationAdmin,
    getOrganizations,
    updateOrg,
    deleteOrgAdmin,
    deleteOrg,
    createOrganizationPricingTier,
    createOrgUnitsLimit
} from "../../api/sdkOrganization"
import Table, { NumberRangeColumnFilter } from "../Table/Table";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";

import EditableField from "../EditableField";
import { OrgName } from "../CreateOrganizationDialog";
import { HouseType } from "../Houses/CreateHouseDialog";
import CreateAdminsEditDialog from "../Houses/AdminsEditDialog";
import { deleteHouse, deleteHouseAdmin } from "../../api/house";
import { ConfirmationDialogRaw } from "../ConfirmDelete";
import { Fragment } from 'react';
import Checkbox from "@material-ui/core/Checkbox";

const adminAccessor = (originalRow, rowIndex, key) => {
    const map = originalRow[key] ? originalRow[key].map(item => item.email) : [];
    let join = map.join();
    return join
}

const tierAccessor = (originalRow, rowIndex, key) => {
    const map = originalRow[key] ? originalRow[key].map(item => `units: ${item.minUnits}, discount: ${item.discount}%, currency: ${item.currency}, type: ${item.unitType}`) : [];
    let join = map.join();
    return join
}

const unitsLimitAccessor = (originalRow, rowIndex, key) => {
    const map = originalRow[key] ? originalRow[key].map(item => `unitsLimit: ${item.unitsLimit}, startDate: ${item.startDate}%, endDate: ${item.endDate}, limitReached: ${item.limitReached}`) : [];
    let join = map.join();
    return join
}

const tierValuesAccessor = (originalRow, rowIndex, key) => {
    const map = originalRow[key] ? originalRow[key].map(item => {
        return {
            minUnits: item.minUnits,
            discount: item.discount,
            currency: item.currency
        }
    }) : [];

    return map
}

export const UNIT_TYPES = {
    AI_UNIT: "AI_UNIT",
    DEVICE: "DEVICE",
    USER: "USER"
}
function Content({ addNewItemMode, setAddNewItemMode, isLoading, isFetchFailed, organizations, createAdmin, removeLocalOrgAdmin, onRemoveItem, getOrganizations, updateOrgPricingTier, updateOrgInState }) {

    const classes = useStyles();

    const [admins, setAdmins] = React.useState([]);
    const [adminsDialogOpen, setAdminsDialogOpen] = React.useState(false);
    const [pricingDialogOpen, setPricingDialogOpen] = React.useState(false);
    const [minUnits, setMinUnits] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [unitType, setUnitType] = React.useState(UNIT_TYPES.AI_UNIT);
    const [currency, setCurrency] = React.useState("usd");
    const [pricingOrgId, setPricingOrgId] = React.useState(null);
    const [savingPrice, setSavingPrice] = React.useState(false);
    const [adminsRes, setAdminsRes] = React.useState(null);
    const [savingAdmins, setAsSvingAdmins] = React.useState(false);
    const [savingAdminsError, setAsSvingAdminsError] = React.useState(null);
    const [unitsLimit, setUnitsLimit] = React.useState(0);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    const [unitsLimitDialogOpen, setUnitsLimitDialogOpen] = React.useState(false);


    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [selected, setSelected] = React.useState(null);

    const handleClose = async (shouldDelete = false) => {
        setOpen(false);

        if (shouldDelete) {
            console.log("selected", selected)
            let res = await onRemoveItem(selected);
            console.log("delete", res)
        }
        setSelected(null)
        setMessage("")
    };



    const resetAdminsDialog = () => {
        setAdmins([])
        setAdminsDialogOpen(false)
        setAdminsRes(null)
        setAsSvingAdmins(false)
        setAsSvingAdminsError(null)
    }

    const resetPricingDialog = () => {
        setPricingDialogOpen(false)
        setMinUnits(0)
        setDiscount(0)
        setCurrency("usd")
        setPricingOrgId(null)
        setUnitType(UNIT_TYPES.AI_UNIT)
    }

    const resetUnitsLimitDialog = () => {
        setUnitsLimit(0)
        setStartDate(null)
        setEndDate(null)
        setPricingOrgId(null)
        setUnitsLimitDialogOpen(false)
    }

    const submitPricing = async () => {
        try{
            console.log("submitPricing", {pricingOrgId, minUnits, discount, unitType})
            setSavingPrice(true)
            let res = await createOrganizationPricingTier({organizationId: pricingOrgId, minUnits, discount, currency, unitType})
            console.log("res", res)
            if(res && res.success) {
                updateOrgPricingTier(pricingOrgId, res.orgPricing)
            }
            setSavingPrice(false)
            resetPricingDialog()
        }catch (e){
            console.error("error saving pricing", e)
            setSavingPrice(false)
        }
    }

    const submitUnitLimit = async () => {
        console.log("submitUnitLimit", {pricingOrgId, unitsLimit, startDate, endDate})
        try {
            let res = await createOrgUnitsLimit({OrgId: pricingOrgId, unitsLimit, startDate, endDate})
            console.log("res", res)
            resetUnitsLimitDialog()
        }catch (e) {
            console.error("error saving units limit", e)
        }
    }

    const onDeleteAdmin = async (id) => {
        try {
            setAsSvingAdmins(true)
            await deleteOrgAdmin(id)
            getOrganizations()
            setAsSvingAdmins(false)
        } catch (e) {
            setAsSvingAdmins(false)
            setAsSvingAdminsError(e)
        }

    }

    const updateField = async ({ id, key, value }) => {
        console.log("updating org", { id, key, value })
        let payload = { id }
        payload[key] = value
        let res = await updateOrg(payload)
        console.log("res", res)
        if(res && res.dataValues){
            updateOrgInState(id, payload)
        }
    }

    console.log("adminsDialogOpen", adminsDialogOpen)


    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                filter: 'fuzzyText',
                Cell: ({ row, value }) => {
                    return <EditableField value={value} renderInput={OrgName} onSave={(value) => {
                        updateField({ id: row.original.id, key: "name", value })
                    }} />
                }
            },
            {
                Header: 'Admins',
                id: "Admins",
                filter: 'fuzzyText',
                accessor: (originalRow, rowIndex) => adminAccessor(originalRow, rowIndex, "OrganizationAdmins"),
                Cell: ({ row, value }) => {
                    return (
                        <EditableField
                            value={value}
                            onEdit={() => {
                                console.log("on edit")
                                setAdminsDialogOpen(true)
                                setAdmins(row.original.OrganizationAdmins)

                            }}
                            onSave={async (id) => {
                                console.log("save", id)

                            }}
                            renderInput={
                                ({ value, setValue, onSave }) => {
                                    return <CreateAdminsEditDialog
                                        result={adminsRes}
                                        admins={admins}
                                        handleClose={resetAdminsDialog}
                                        onSubmit={async (id) => {
                                            await onDeleteAdmin(id)
                                            onSave()
                                            removeLocalOrgAdmin(row.original.id, id)
                                        }}
                                        uploading={savingAdmins}
                                        uploadError={savingAdminsError}
                                        open={adminsDialogOpen}
                                    />
                                }
                            }
                        />
                    )
                }
            },
            {
                Header: 'Tier',
                id: "Tier",
                filter: 'fuzzyText',
                accessor: (originalRow, rowIndex) => tierAccessor(originalRow, rowIndex, "OrgPricings"),
                Cell: ({ row, value }) => {

                    return value ? (
                        <EditableField
                            value={value}
                            onEdit={() => {
                                console.log("on edit", row.original.id)

                                let values = tierValuesAccessor(row.original, 0, "OrgPricings")

                                setMinUnits(_.get(values, "[0].minUnits", 0))
                                setDiscount(_.get(values, "[0].discount", 0))
                                setCurrency(_.get(values, "[0].currency", "usd"))
                                setUnitType(_.get(values, "[0].unitType", UNIT_TYPES.AI_UNIT))

                                setPricingOrgId(row.original.id)
                                setPricingDialogOpen(true)

                            }}
                            onSave={async (id) => {
                                console.log("save", id)

                            }}
                        />
                    ) :
                        (
                            <Button
                                onClick={() => {
                                    //house id - value.id
                                    console.log("create pricing clicked", row.original.id)
                                    setPricingOrgId(row.original.id)
                                    setPricingDialogOpen(true)
                                  //  createAdmin(value.id)
                                }}
                            >
                                {"Add Pricing Tier"}
                            </Button>
                        )

                }
            },
            {
                Header: 'Units Limit',
                id: "limit",
                filter: 'fuzzyText',
                accessor:  (originalRow, rowIndex) => unitsLimitAccessor(originalRow, rowIndex, "AIUnitsLimits"),
                Cell: ({ row, value }) => {

                    return value ? (
                            <EditableField
                                value={value}
                                onEdit={() => {
                                    console.log("on edit", row.original.id)

                                    let values = unitsLimitAccessor(row.original, 0, "AIUnitsLimits")

                                    setUnitsLimit(_.get(values, "[0].unitsLimit", 0))
                                    setStartDate(_.get(values, "[0].startDate", null))
                                    setEndDate(_.get(values, "[0].endDate", null))


                                    setPricingOrgId(row.original.id)
                                    setUnitsLimitDialogOpen(true)

                                }}
                                onSave={async (id) => {
                                    console.log("save", id)

                                }}
                            />
                        ) :
                        (
                            <Button
                                onClick={() => {
                                    //house id - value.id
                                    console.log("create units limit clicked", row.original.id)
                                    setPricingOrgId(row.original.id)
                                    setUnitsLimitDialogOpen(true)
                                    //  createAdmin(value.id)
                                }}
                            >
                                {"Add Units Limit"}
                            </Button>
                        )

                }
            },
            {
                id: "collectData",
                Header: "Collect usage data",
                accessor:  "collectUsageData",
                filter: null,
                Filter: () => null,
                Cell: ({ row, value }) => {
                    return (<Checkbox
                        checked={value}
                        color="primary"
                        onChange={() => {
                            //   updateUsageDataInServer(value.id, !value.collectUsageData)
                               //updateMyData(row.index, "collectUsageData", !value)
                            console.log("collectData",!value, { id: row.original.id, key: "collectUsageData", value: !value})
                            updateField({ id: row.original.id, key: "collectUsageData", value: !value})
                        }}
                    />)
                }
            },
            {
                id: "appLevelUnitsLimitEnabled",
                Header: "app level limit",
                accessor:  "appLevelUnitsLimitEnabled",
                filter: null,
                Filter: () => null,
                Cell: ({ row, value }) => {
                    return (<Checkbox
                        checked={value}
                        color="primary"
                        onChange={() => {
                            //   updateUsageDataInServer(value.id, !value.collectUsageData)
                            //updateMyData(row.index, "collectUsageData", !value)
                            console.log("enableAppLevelUnitsLimit",!value, { id: row.original.id, key: "appLevelUnitsLimitEnabled", value: !value})
                            updateField({ id: row.original.id, key: "appLevelUnitsLimitEnabled", value: !value})
                        }}
                    />)
                }
            },
            {
                id: "actions",
                Header: "",
                accessor: (originalRow, rowIndex) => ({ id: originalRow.id }),
                filter: null,
                Filter: () => null,
                Cell: ({ value }) => (
                    <Button
                        onClick={() => {
                            //house id - value.id
                            console.log("create admin clicked", value.id)
                            createAdmin(value.id)
                        }}
                    >
                        {"Add Admin"}
                    </Button>
                )
            },
            {
                id: "actions2",
                Header: "",
                accessor: (originalRow, rowIndex) => ({ id: originalRow.id, name: originalRow.name }),
                filter: null,
                Filter: () => null,
                Cell: ({ value }) => (
                    <IconButton color="primary" aria-label="delete" component="span" onClick={() => {
                        setMessage(`Are you sure you want to delete "${value.name}"?`)
                        setOpen(true)
                        setSelected(value.id)
                    }}>
                        <Delete />
                    </IconButton>
                )
            }
        ],
        [admins,
            adminsDialogOpen,
            adminsRes,
            savingAdmins,
            pricingDialogOpen,
            savingAdminsError]
    )

    return (
        <div className={classes.root}>

            <Button
                variant="contained"
                component="span"
                color={addNewItemMode ? "secondary" : "primary"}
                onClick={() => {
                    setAddNewItemMode(!addNewItemMode)
                }}
            >
                {addNewItemMode ? "Cancel" : "Create SDK Organization"}
            </Button>
            {addNewItemMode ? (
                <Button
                    variant="contained"
                    component="span"
                    color={"primary"}
                    style={{ marginLeft: 16 }}
                >
                    Save
                </Button>
            ) : null}

            {isLoading ? (
                <CircularProgress />
            ) : isFetchFailed ? (
                "Error"
            ) :
                (
                    <Fragment>
                        < Table
                            columns={columns}
                            data={organizations}
                        />
                    </Fragment>

                )}
            <ConfirmationDialogRaw
                classes={{
                    paper: classes.paper,
                }}
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                message={message}
                title={"Confirm Delete"}
            />

            <OrgPricingDialog
                handleClose={resetPricingDialog}
                onSubmit={submitPricing}
                open={pricingDialogOpen}
                setDiscount={setDiscount}
                setMinUnits={setMinUnits}
                minUnits={minUnits}
                discount={discount}
                currency={currency}
                setCurrency={setCurrency}
                setUnitType={setUnitType}
                unitType={unitType}
            />

            <UnitsLimitDialog
                handleClose={resetUnitsLimitDialog}
                onSubmit={submitUnitLimit}
                open={unitsLimitDialogOpen}
                setUnitsLimit={setUnitsLimit}
                unitsLimit={unitsLimit}
                setStartDate={setStartDate}
                startDate={startDate}
                setEndDate={setEndDate}
                endDate={endDate}
            />
        </div>
    );
}

class SdkOrganizations extends Component {

    constructor(props) {
        super(props);

        this.state = {
            addNewItemMode: false,
            addAdminMode: false,

            uploading: false,
            uploadError: false,
            orgHouseId: null,
            createdAdmin: null,
            createdOrg: null,

        };
    }

    componentDidMount() {
        this.props.getOrganizations()
    }

    closeCreateAdminDialog = () => {
        this.setState({
            addAdminMode: false,
            orgHouseId: null,
            createdAdmin: null
        })
    }

    submit = async (org) => {
        console.log("saving organization", org)
        try {
            this.setState({ uploading: true })
            let res = await createOrganization(org)
            console.log("res", res);
            this.setState({ createdOrg: res })
            this.setState({ uploading: false })
            // this.props.getOrganizations()
            this.props.addOrgToState(res)
        } catch (e) {
            console.error("error creating organization", e)
            alert("error creating organization")
            this.props.deleteOrgFromState(undefined)
            this.setState({ uploading: false, uploadError: true })
        }
    }

    submitAdmin = async ({ name, email }) => {
        try {
            this.setState({ uploading: true })
            const { orgHouseId } = this.state;
            let res = await createOrganizationAdmin({ name, email, organizationId: orgHouseId })

            if (res && res.errors) {
                this.setState({ uploading: false, uploadError: true })
                return
            }

            this.setState({ uploading: false, createdAdmin: res })
            this.props.getOrganizations()
        } catch (e) {
            console.error("error creating house admin", e)
            this.setState({ uploading: false, uploadError: true })
        }


    }

    onRemove = async (orgId) => {
        const { organizations } = this.props;
        const orgIndex = _.findIndex(organizations, { id: orgId })
        const org = organizations[orgIndex]
        try {
            let res = await deleteOrg({ id: orgId })
            // this.props.getOrganizations()
            this.props.deleteOrgFromState(orgId)
        } catch (e) {
            console.error("onRemove error", e)
            alert("Error Removing organization")
            this.props.addOrgToState(org)
        }
    }

    render() {
        const { uploading, uploadError, createdAdmin, createdOrg } = this.state;
        const { organizations, isLoading, isFetchFailed } = this.props;

        console.log("isFetchFailed", isFetchFailed)

        return (
            <div style={{ flex: 1, height: "100%", width: "100%" }}>

                <Content
                    organizations={organizations}
                    isFetchFailed={isFetchFailed}
                    isLoading={isLoading}
                    addNewItemMode={this.state.addNewItemMode}
                    setAddNewItemMode={(addNewItemMode) => { this.setState({ addNewItemMode }) }}
                    createAdmin={(orgHouseId) => { this.setState({ addAdminMode: true, orgHouseId }) }}
                    removeLocalOrgAdmin={(orgId, adminId) => {
                        let index = _.findIndex(organizations, { id: orgId })
                        if (index === -1) {
                            return
                        }
                        _.remove(organizations[index].OrganizationAdmins, (a) => {
                            return a.id === adminId
                        })

                        this.setState({ organizations })

                    }}
                    onRemoveItem={this.onRemove}
                    getOrganizations={this.props.getOrganizations}
                    updateOrgPricingTier={this.props.updateOrgPricingTier}
                    updateOrgInState={this.props.updateOrgInState}
                />
                <CreateOrganizationDialog
                    open={this.state.addNewItemMode}
                    handleClose={() => {
                        this.setState({
                            addNewItemMode: false,
                            createdOrg: null
                        });
                    }}
                    formProps={this.props}
                    onSubmit={this.submit}
                    uploading={uploading}
                    uploadError={uploadError}
                    createdOrg={createdOrg}

                />

                <CreateOrganizationAdminDialog
                    handleClose={this.closeCreateAdminDialog}
                    onSubmit={this.submitAdmin}
                    createdAdmin={createdAdmin}
                    uploading={uploading}
                    uploadError={uploadError}
                    open={this.state.addAdminMode}
                />


            </div>
        );
    }
}

SdkOrganizations.propTypes = {};

const mapStateToProps = ({ sdkOrganizations }) => ({
    organizations: sdkOrganizations.organizations,
    filtered: sdkOrganizations.filtered,
    isLoading: sdkOrganizations.isLoading || sdkOrganizations.isLoadingSearch,
    isFetchFailed: sdkOrganizations.isFetchFailed,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getOrganizations,
            addOrgToState,
            deleteOrgFromState,
            updateOrgPricingTier,
            updateOrgInState
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SdkOrganizations);


