import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import therapyContent from './therapyContent'
import youtubePlaylists from './youtubePlaylists'
import youtubeSongs from "./youtubeSongs";
import housesReducer from "./houses"
import organizationsReducer from "./organizations";
import sdkOrganizationsReducer from "./sdkOrganizations";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    therapyContent,
    youtubePlaylists,
    youtubeSongs,
    houses: housesReducer,
    organizations: organizationsReducer,
    sdkOrganizations: sdkOrganizationsReducer
})

export default createRootReducer




