import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { mainListItems } from './listItems';
import { signOut } from '../../api/firebase';
import { Button } from '@material-ui/core';
import TherapyContent from '../../components/therapyContent'
import YoutubePlaylists from '../../components/youtubePlaylists'
import HouseScreen from "../../components/Houses"
import YoutubeSongs from "../../components/youtubeSongs";
import SoloSessions from "../../components/soloSessions"
import Translations from "../../components/translations";
import Organizations from "../../components/organizations";
import MediaUpload from "../../components/mediaUpload";
import SdkOrganizations from "../../components/SdkOrganizations";
import VideocamIcon from '@material-ui/icons/Videocam';
import HistoryIcon from '@material-ui/icons/History';
import TranslateIcon from '@material-ui/icons/Translate';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import ListIcon from '@material-ui/icons/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BusinessIcon from '@material-ui/icons/Business';
import PermMediaIcon from '@material-ui/icons/PermMedia';

import { Route, withRouter } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedScreen, setSelectedScreen] = React.useState("house");
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    function handleSignOut() {
        signOut().catch(error => console.log(error));
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(
                            classes.menuButton,
                            open && classes.menuButtonHidden
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        2gether CMS
                    </Typography>
                    {/* <IconButton color="inherit" onClick={handleSignOut}>
            Sign out
          </IconButton> */}
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={handleSignOut}
                    >
                        Sign out
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>

                    <ListItem
                        button
                        onClick={() => {
                            setSelectedScreen("youtubeSongs");
                        }}
                    >
                        <ListItemIcon>
                            <LibraryMusicIcon
                                color={
                                    selectedScreen === "youtubeSongs" ? "primary" : "default"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="Youtube Songs" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => {
                            setSelectedScreen("translations");
                        }}
                    >
                        <ListItemIcon>
                            <TranslateIcon
                                color={
                                    selectedScreen === "translations" ? "primary" : "default"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="Translations" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => {
                            setSelectedScreen("house");
                        }}
                    >
                        <ListItemIcon>
                            <AccountBalanceIcon
                                color={
                                    selectedScreen === "house" ? "primary" : "default"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="House" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => {
                            setSelectedScreen("org");
                        }}
                    >
                        <ListItemIcon>
                            <BusinessIcon
                                color={
                                    selectedScreen === "org" ? "primary" : "default"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="Organization" />
                    </ListItem>


                    <ListItem
                        button
                        onClick={() => {
                            setSelectedScreen("mediaUpload");
                        }}
                    >
                        <ListItemIcon>
                            <PermMediaIcon
                                color={
                                    selectedScreen === "mediaUpload" ? "primary" : "default"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="Media" />
                    </ListItem>

                    <ListItem
                        button
                        onClick={() => {
                            setSelectedScreen("sdk-org");
                        }}
                    >
                        <ListItemIcon>
                            <BusinessIcon
                                color={
                                    selectedScreen === "sdk-org" ? "primary" : "default"
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="SDK Organizations" />
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                    {selectedScreen === "therapyContent" ? (
                        <TherapyContent />
                    ) : selectedScreen === "youtubePlaylists" ? (
                        <YoutubePlaylists />
                    ) : selectedScreen === "youtubeSongs" ? (
                        <YoutubeSongs />
                    ) : selectedScreen === "soloSessions" ? (
                        <SoloSessions />
                    ) : selectedScreen === "house" ? (
                        <HouseScreen />
                    ) : selectedScreen === "org" ? (
                        <Organizations />
                    ) : selectedScreen === "translations" ? (
                        <Translations />
                    ) : selectedScreen === "mediaUpload" ? (
                        <MediaUpload />
                    ) : selectedScreen === "sdk-org" ? (
                        <SdkOrganizations />
                    ) : null}
                </Container>
            </main>
        </div>
    );
}
