import axios from "axios";

console.log("STAGING", process.env.REACT_APP_STAGING)

export const apiUrl = process.env.REACT_APP_STAGING ? 'https://solo-staging.uc.r.appspot.com' : !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://practical-theme-289410.uc.r.appspot.com';
export const SdkApiUrl = process.env.REACT_APP_STAGING ? 'https://solo-sdk-332507.uc.r.appspot.com' : !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://solo-sdk-332507.uc.r.appspot.com';
//const apiUrl ='https://practical-theme-289410.uc.r.appspot.com';

// export const apiUrl = process.env.REACT_APP_STAGING ? 'https://solo-staging.uc.r.appspot.com' : !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://practical-theme-289410.uc.r.appspot.com';
// export const SdkApiUrl = process.env.REACT_APP_STAGING ? 'https://solo-sdk-332507.uc.r.appspot.com' : !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://solo-sdk-332507.uc.r.appspot.com';

export const api = axios.create({
    baseURL: apiUrl,
    timeout: 20000,
});

export const apiRoutes = {
    uploadCSV: apiUrl + "/api/cms/songs/upload",
    media: (id) => apiUrl + `/api/cms/songs/${id}`,
    getAllSongs: apiUrl + "/api/cms/songs",
    deleteSong: (id) => apiUrl + `/api/cms/songs/${id}`,
    getSessions: apiUrl + "/api/cms/sessions",
    uploadTranslations: apiUrl + "/api/cms/translation/upload",
    createHouse: apiUrl + "/api/cms/house",
    house: (id) => apiUrl + `/api/cms/house/${id}`,
    createHouseAdmin: apiUrl + "/api/cms/house/admin",
    houseAdmin: (id) => apiUrl + `/api/cms/house/admin/${id}`,
    houseOrganization: (id) => apiUrl + `/api/cms/house/organization/${id}`,
    getHouses: apiUrl + "/api/cms/houses",
    organizations: apiUrl + "/api/cms/organizations",
    organization: (id) => apiUrl + `/api/cms/organizations/${id}`,
    addOrganizationToHouse: apiUrl + "/api/cms/organizations/house",
    createOrganizationAdmin: apiUrl + "/api/cms/organizations/admin",
    orgAdmin: (id) => apiUrl + `/api/cms/organizations/admin/${id}`,
    sdkOrganizations: SdkApiUrl + "/api/v1/admin/organizations",
    sdkOrganization: (id) => SdkApiUrl + `/api/v1/admin/organizations/${id}`,
    createSdkOrganizationAdmin: SdkApiUrl + "/api/v1/admin/organizationAdmin",
    sdkOrgAdmin: (id) => SdkApiUrl + `/api/v1/admin/organizationAdmin/${id}`,
    createSdkOrgPricingTier: SdkApiUrl + "/api/v1/admin/organization-pricing",
    createSdkOrgUnitsLimit: SdkApiUrl + "/api/v1/admin/units-limit",
    updateSdkOrgUnitsLimit:(id) =>  SdkApiUrl + "/api/v1/admin/units-limit/"+id,
    linkSDKOrg: (id) => apiUrl + `/api/cms/organizations/${id}/link-sdk-org`
}

export const getAuthHeader = () => {
    const token = localStorage.getItem("userToken")
    return new Headers({
        'Authorization': `Bearer ${token}`
    })
}
